import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout title="Success">
    <SEO title="Confirmation" />
    <section>
      <div className="container">
       <h1 className="title">Your message has been sent!</h1>
       <h2 className="subtitle">We will be in touch shortly.</h2>
       <div>
         <Link className="button is-info" to="/">Back home</Link>
       </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage